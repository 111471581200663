export default {
  data: function data() {
    return {
      isShow: false,
      options: [{
        address: '浙江省杭州市西湖区教工路18号欧美中心B区901室',
        tel: '0571-87217082'
      }, {
        address: '北京市丰台区万丰路316号万开中心A座520室',
        tel: '010-83277369'
      }, {
        address: '上海市徐汇区淮海西路55号申通信息广场21楼B/C座',
        tel: '021-52308695'
      }, {
        address: '广州市天河区体育西路109号高盛大厦23楼A单元',
        tel: '020-87392779\n87395995\n020-87399596'
      }, {
        address: '深圳市福田区福华三路88号财富大厦10A',
        tel: '0755-83278556'
      }, {
        address: '江苏省南京市秦淮区太平南路168号远洋国际中心2栋726室',
        tel: '025-84560218'
      }, {
        address: '湖北省武汉市青年路161号ICC武汉环贸中心A座1905',
        tel: '027-88185509'
      }, {
        address: '成都市武侯区领事馆7号保利南塔2501',
        tel: '02887468177\n0288746551'
      }, {
        address: '重庆市江北区观音桥融恒时代2406',
        tel: '023-86831188'
      }, {
        address: '天津市南开区长江道9号金融街中心A座802',
        tel: '022-58956702'
      }, {
        address: '河北省石家庄市桥西区华润大厦A座1813室',
        tel: '031166099518 '
      }, {
        address: '浙江省宁波市海曙区华楼巷19号天一豪景a座704室诺特健康 ',
        tel: '0574-87173878'
      }, {
        address: '山东省济南市槐荫区和谐广场银座中心2号楼901室 ',
        tel: '0531-87067739'
      }, {
        address: '山东省青岛市北区卓越世纪中心4号楼1506室',
        tel: '0532-58582238'
      }, {
        address: '湖南省长沙市开福区泊富国际金融中心写字楼19028',
        tel: '0731-84254356'
      }, {
        address: '安徽省合肥市蜀山区置地投资广场2506室',
        tel: '0557-62883233'
      }, {
        address: '郑州市管城区紫荆山路二里岗正商蓝海广场1栋 402 ',
        tel: '0371-63687500'
      }, {
        address: '福建省福州市台江区宁化街道富力中心A座713',
        tel: '0591-83280207'
      }, {
        address: '陕西省西安市碑林区长安北路十字东南角大话南门1中心1408室',
        tel: '029—89350959'
      }, {
        address: '浙江省温州市鹿城区五马街道世贸中心大厦2105-2',
        tel: ''
      }, {
        address: '浙江省金华市金东区金华万达广场4号楼709室',
        tel: '057982392300'
      }, {
        address: '海南省海口市龙华区海秀中路海垦广场B座1303A',
        tel: ''
      }, {
        address: '江西省南昌市西湖区广场区广场南路205号恒茂国际华城16栋A座507室',
        tel: ''
      }, {
        address: '内蒙古自治区呼和浩特市赛罕区乌兰察布东街中星国际1210',
        tel: ''
      }, {
        address: '甘肃省兰州市城关区东岗东路2704号万商国际C塔17楼诺特健康管理中心',
        tel: '09314682201'
      }, {
        address: '山西省太原市小店区长风街705号和信商座1幢923-924室',
        tel: '03518066822'
      }, {
        address: '黑龙江省哈尔滨市南岗区哈西万达写字楼B1栋2403',
        tel: '045151075398'
      }, {
        address: '辽宁省大连市沙河口二街（路）5号第一栋第19层第02-2单元',
        tel: '0411-86659669'
      }, {
        address: '辽宁省沈阳市沈河区青年大街夏宫城市广场3415',
        tel: '024-81054948'
      }, {
        address: '新疆乌鲁木齐市天山区青年路街道红山路一六号时代广场D座19楼M室',
        tel: '0991-2697700'
      }, {
        address: '云南省昆明市西山区人民西路207号保利中心510室',
        tel: '0871-63167765'
      }, {
        address: '贵州省贵阳市南明区泰祥国际B座1309',
        tel: '0851-85868247'
      }
      // { address: '广西省南宁市青秀区民族大道115号现代国际10楼-11（12）室', tel: '0771-5381739' },
      ]
    };
  },

  methods: {}
};